import 'styles/styles.scss';
import 'react-toastify/dist/ReactToastify.css';

import { IMAGEKIT_ENDPOINT } from 'config/constants';
import Routes from 'config/routes';
import { IKContext } from 'imagekitio-react';
import React, { memo } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

function App() {
    return (
        <div>
            <IKContext urlEndpoint={IMAGEKIT_ENDPOINT}><Switch>
                <Route component={Routes} />
            </Switch></IKContext>
            <ToastContainer />
        </div>
    );
}

export default memo(App);
