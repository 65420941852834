const header = {
    en: {
        HEADER_JOIN: 'Try Weyoco',
        HEADER_FEED: 'Home',
        HEADER_STORIES: 'Stories',
        HEADER_RECIPES: 'Find my account',
        HEADER_LIVE: 'LIVE',
        HEADER_CHALLENGES: 'Challenges',
        HEADER_EVENTS: 'Events',
        LANGUAGE_TITLE: 'Change Language',
        LANGUAGE_ENGLISH: 'English',
        LANGUAGE_INDO: 'Bahasa Indonesia',
        LANGUAGE_CONFIRM: 'Confirm',
        HEADER_ACCOUNT: 'Account',
        MY_PROFILE: 'My Profile',
        LOG_OUT: 'Log Out',
        CONFIRM_LOGOUT: 'Are you sure you want to Log out?',
        CONFIRM_YES: 'Yes',
        SEARCH_PLACEHOLDER: 'Search in YoRipe',
        HEADER_USE_APP: 'Use App',
        HEADER_MORE: 'More'
    },
    id: {
        HEADER_JOIN: 'Bergabung',
        HEADER_FEED: 'Beranda',
        HEADER_STORIES: 'Cerita',
        HEADER_RECIPES: 'Temukan akun saya',
        HEADER_LIVE: 'LIVE',
        HEADER_CHALLENGES: 'Tantangan Seru',
        HEADER_EVENTS: 'Acara',
        LANGUAGE_TITLE: 'Ganti Bahasa',
        LANGUAGE_ENGLISH: 'English',
        LANGUAGE_INDO: 'Bahasa Indonesia',
        LANGUAGE_CONFIRM: 'Konfirmasi',
        HEADER_ACCOUNT: 'Akun',
        MY_PROFILE: 'Profil Saya',
        LOG_OUT: 'Keluar',
        CONFIRM_LOGOUT: 'Apa kamu yakin ingin keluar?',
        CONFIRM_YES: 'Ya',
        SEARCH_PLACEHOLDER: 'Cari di YoRipe',
        HEADER_USE_APP: 'Buka App',
        HEADER_MORE: 'Lainnya'
    }
};

export default header;
