import { ENVIRONMENT } from 'config/constants';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga4';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import authentication from './modules/authentication';

export const history = createBrowserHistory();

history.listen((location) => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
});

const appReducer = combineReducers({
    router: connectRouter(history),
    authentication
});

const middleware = applyMiddleware(routerMiddleware(history), thunk);
const store = createStore(appReducer, ENVIRONMENT === 'development' ? composeWithDevTools(middleware) : middleware);

export default store;
