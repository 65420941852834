import './config/i18n';

import loadable from '@loadable/component';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import branch from 'branch-sdk';
import App from 'containers/App';
import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import store from 'utils/redux';

import reportWebVitals from './reportWebVitals';

const SomethingWrong = loadable(() => import('components/SomethingWrong'));

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    autoSessionTracking: false,
    environment: process.env.REACT_APP_ENV
});

branch.init('key_live_ckIzmlzYoK1Qnm0tc2BvkkgoByog1Fi3');

// const options = {
//     autoConfig: true,
//     debug: false // enable logs
// };

const tagManagerArgs = {
    gtmId: 'GTM-559FLW4',
    dataLayerName: 'LPDataLayer'
};

TagManager.initialize(tagManagerArgs);

// ReactPixel.init('539802963948098', options);

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <Sentry.ErrorBoundary fallback={<SomethingWrong />}>
                    <App />
                </Sentry.ErrorBoundary>
            </BrowserRouter>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
