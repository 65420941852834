const APP_READY = 'APP_READY';
const AUTHENTICATING = 'AUTHENTICATING';
const AUTH_FAILED = 'AUTH_FAILED';
const IS_AUTHED = 'IS_AUTHED';
const IS_VERIFIED = 'IS_VERIFIED';
const UPDATE_PROFILE_DATA = 'UPDATE_PROFILE_DATA';
const UPDATE_ONBOARDING_DATA = 'UPDATE_ONBOARDING_DATA';
const TOGGLE_SIGN_UP = 'TOGGLE_SIGN_UP';
const ONBOARDING_DATA_DONE = 'ONBOARDING_DATA_DONE';
const TOGGLE_LOGIN_MODAL = 'TOGGLE_LOGIN_MODAL';
const TOGGLE_PROFILE_MODAL = 'TOGGLE_PROFILE_MODAL';
const UPDATE_COUNTRY = 'UPDATE_COUNTRY';
const UPDATE_DEEPLINK = 'UPDATE_DEEPLINK';

export const LOGGING_OUT = 'LOGGING_OUT';

const DEFAULT_ONBOARDING_DATA = {
    goals: [],
    dietaries: [1],
    allergies: [],
    cooking_experience: null,
    isFinished: false,
    hasSeenOnboarding: false,
    ingredients: [],
    isEverythingSelected: true
};

export function appReady() {
    return {
        type: APP_READY
    };
}

export function isAuthed(data) {
    // use the "getAttributes" method of api sdk to get plain object from api response instead of class
    if (data.onboardingData) {
        return {
            type: IS_AUTHED,
            data: data.data._getAttributes ? data.data._getAttributes() : data.data,
            onboardingData: data.onboardingData
        };
    }

    return {
        type: IS_AUTHED,
        data: data.data._getAttributes ? data.data._getAttributes() : data.data
    };
}

export function isVerified(data) {
    return {
        type: IS_VERIFIED,
        data: data
    };
}

export function loggingOut() {
    return {
        type: LOGGING_OUT
    };
}

export function updateProfileData(data) {
    // use the "getAttributes" method of api sdk to get plain object from api response instead of class
    return {
        type: UPDATE_PROFILE_DATA,
        data: data._getAttributes ? data._getAttributes() : data
    };
}

export function updateOnboardingData(data, isDoneOnboarding = null) {
    return {
        type: UPDATE_ONBOARDING_DATA,
        data,
        isDoneOnboarding
    };
}

export function doneOnboardingData() {
    return {
        type: ONBOARDING_DATA_DONE
    };
}

export function toggleLoginModal(login = true) {
    return {
        type: TOGGLE_LOGIN_MODAL,
        login: login
    };
}

export function toggleProfileModal() {
    return {
        type: TOGGLE_PROFILE_MODAL
    };
}

export function switchCountry(country = 'en') {
    return {
        type: UPDATE_COUNTRY,
        country: country
    };
}

export function updateDeeplinkUrl(url) {
    return {
        type: UPDATE_DEEPLINK,
        url: url
    };
}

const initialState = {
    isAppReady: false,
    isAuthenticating: false,
    isAuthed: false,
    profileData: {},
    onboardingData: DEFAULT_ONBOARDING_DATA,
    isDoneOnboarding: false,
    isSignUpVisible: false,
    showLoginModal: false,
    country: 'en',
    login: true,
    showCompleteProfile: false,
    deeplinkUrl: ''
};

export default function authentication(state = initialState, action) {
    switch (action.type) {
        case APP_READY:
            return {
                ...state,
                isAppReady: true
            };

        case AUTHENTICATING:
            return {
                ...state,
                isAuthenticating: true
            };

        case AUTH_FAILED:
            return {
                ...state,
                isAuthenticating: false
            };

        case IS_AUTHED:
            return {
                ...state,
                isAuthenticating: false,
                isAuthed: true,
                profileData: action.data,
                onboardingData: action.onboardingData || state.onboardingData,
                isSignUpVisible: false,
                isDoneOnboarding: true
            };

        case UPDATE_PROFILE_DATA:
            return {
                ...state,
                profileData: {
                    ...state.profileData,
                    ...action.data
                }
            };

        case UPDATE_ONBOARDING_DATA:
            return {
                ...state,
                onboardingData: action.data,
                isDoneOnboarding: action.isDoneOnboarding || state.isDoneOnboarding
            };

        case TOGGLE_SIGN_UP:
            return {
                ...state,
                isSignUpVisible: action.value,
                signUpToggleData: action.signUpToggleData
            };

        case ONBOARDING_DATA_DONE:
            return {
                ...state,
                isDoneOnboarding: true
            };

        case LOGGING_OUT:
            return {
                isAppReady: true,
                isAuthenticating: false,
                isAuthed: false,
                profileData: {},
                onboardingData: DEFAULT_ONBOARDING_DATA,
                isDoneOnboarding: true,
                isSignUpVisible: false
            };

        case IS_VERIFIED:
            return {
                ...state,
                profileData: action.data
            };

        case TOGGLE_LOGIN_MODAL:
            return {
                ...state,
                showLoginModal: !state.showLoginModal,
                login: action.login
            };
        case TOGGLE_PROFILE_MODAL:
            return {
                ...state,
                showCompleteProfile: !state.showCompleteProfile
            };
        case UPDATE_COUNTRY:
            return {
                ...state,
                country: action.country
            };

        case UPDATE_DEEPLINK:
            return {
                ...state,
                deeplinkUrl: action.url
            };

        default:
            return state;
    }
}
