import AirFryer from 'images/ChefIdolEvent/AirFryer.webp';
import Beef from 'images/ChefIdolEvent/Beef.webp';
import Bread from 'images/ChefIdolEvent/Bread.webp';
import Bryan from 'images/ChefIdolEvent/Bryan.webp';
import ChefKai from 'images/ChefIdolEvent/ChefKai.webp';
import Dessert from 'images/ChefIdolEvent/Dessert.webp';
import DianaGale from 'images/ChefIdolEvent/DianaGale.webp';
import EricNeo from 'images/ChefIdolEvent/EricNeo.webp';
import EuropAce from 'images/ChefIdolEvent/EuropAce.webp';
import FoodTrends from 'images/ChefIdolEvent/FoodTrends.webp';
import Indomie from 'images/ChefIdolEvent/Indomie.webp';
import JamieYeo from 'images/ChefIdolEvent/JamieYeo.webp';
import KidsAndFamily from 'images/ChefIdolEvent/KidsAndFamily.webp';
import LeonLin from 'images/ChefIdolEvent/LeonLin.webp';
import LeslieKoh from 'images/ChefIdolEvent/Leslie.webp';
import PangKok from 'images/ChefIdolEvent/PangKok.webp';
import RiceField from 'images/ChefIdolEvent/RiceF.webp';
import SanRemo from 'images/ChefIdolEvent/SanRemo.webp';
import Seaco from 'images/ChefIdolEvent/Seaco.webp';
import SeaFood from 'images/ChefIdolEvent/SeaFood.webp';
import SongHe from 'images/ChefIdolEvent/SongH.webp';
import Spicy from 'images/ChefIdolEvent/Spicy.webp';
import TrueAussie from 'images/ChefIdolEvent/TrueAussieBeefAndLamb.webp';
import Western from 'images/ChefIdolEvent/Western.webp';
import Meg from 'images/IndoJuniorChef/Meg.webp';
import Syaiful from 'images/IndoJuniorChef/Syaiful.webp';
import Xinyan from 'images/IndoJuniorChef/Xinyan.webp';
import Zaidan from 'images/IndoJuniorChef/Zaidan.webp';
import AllsWell from 'images/JuniorIdol/AllsWell.png';
import Crispy from 'images/JuniorIdol/Crispy.jpg';
import KateInsigne from 'images/JuniorIdol/KateInsigne.jpg';
import Sustenir from 'images/JuniorIdol/Sustenir.png';
import Tri from 'images/JuniorIdol/Tri.jpg';
import BVLogo from 'images/RamadanIndo/BVLogo.png';
import HipweeYoung from 'images/RamadanIndo/HipweeYoung.png';
import Juri1 from 'images/RamadanIndo/juri-1.png';
import Juri2 from 'images/RamadanIndo/juri-2.png';
import Juri3 from 'images/RamadanIndo/juri-3.png';
import Juri4 from 'images/RamadanIndo/juri-4.png';
import Juri5 from 'images/RamadanIndo/juri-5.png';
import KawanMommel from 'images/RamadanIndo/KawanMommel.jpg';
import Kompetisi1 from 'images/RamadanIndo/kompetisi-1.png';
import Kompetisi2 from 'images/RamadanIndo/kompetisi-2.png';
import Kompetisi3 from 'images/RamadanIndo/kompetisi-3.png';
import Kompetisi4 from 'images/RamadanIndo/kompetisi-4.png';
import Kompetisi5 from 'images/RamadanIndo/kompetisi-5.png';
import MaduEnak from 'images/RamadanIndo/MaduEnak.png';
import MamaSupport from 'images/RamadanIndo/MamaSupport.jpg';
import MegBrand from 'images/RamadanIndo/MegBrand.webp';
import MomToMom from 'images/RamadanIndo/MomToMom.png';
import SocialConnext from 'images/RamadanIndo/SocialConnext.png';
import Timeline1 from 'images/RamadanIndo/timeline-1.png';
import Timeline2 from 'images/RamadanIndo/timeline-2.png';
import Timeline3 from 'images/RamadanIndo/timeline-3.png';
import Timeline4 from 'images/RamadanIndo/timeline-4.png';
import Timeline5 from 'images/RamadanIndo/timeline-5.png';
import YeosBrand from 'images/RamadanIndo/YeosBrand.png';

export const APP_URL = process.env.REACT_APP_URL;
export const S3_MEDIA_BUCKET_URL = process.env.REACT_APP_S3_MEDIA_BUCKET_URL;
export const GA_KEY = process.env.REACT_APP_GA_KEY;
export const IMAGEKIT_ENDPOINT = process.env.REACT_APP_IMAGEKIT_ENDPOINT;
export const AGORA_ID = process.env.REACT_APP_AGORA_APP_ID;
export const PUSHER_KEY = process.env.REACT_APP_PUSHER_KEY;
export const ENVIRONMENT = process.env.REACT_APP_ENV;

export const pusherChannel = {
    user: {
        all: 'App.User.All'
    }
};

export const pusherEvents = {
    LiveStreamUpdated: 'App\\Events\\LiveStreamUpdated',
    LiveStreamReactions: 'App\\Events\\LiveStreamReactions',
    LiveChatMessageCreated: 'App\\Events\\LiveChatMessageCreated'
};

export const FEED_TYPE = {
    RECIPE: 'recipe',
    REVIEW: 'review',
    LIVESTREAM: 'livestream'
};

// Local Storage Keys
export const LOGIN_TOKEN_STORAGE_KEY = 'YoripeLoginToken';
export const APP_LANGUAGE = 'YoripeLanguage';
export const IS_FIRST_OPEN = 'IS_FIRST_OPEN';

export const googleApiKey = 'AIzaSyCc-7cU3-_x1VTV5eW3g2pVnl3vi9lvv7w';
export const googleRecaptchSiteKey = '6LcsiD0pAAAAAA_GDKtxkFayhfHdVCZrl7gpWWh_';

export const googleClientIds = {
    webClientId: '20281048316-373qcpuc5arcjaesd654tm1dluolbten.apps.googleusercontent.com'
};

export const thumbnailPlaceholder = 'https://yoripe-s3-production.s3.ap-southeast-1.amazonaws.com/assets/logo/YoRipe_logo2.png';
export const livestreamDefaultThumbnailUrl = 'https://yoripe-s3-production.s3.ap-southeast-1.amazonaws.com/assets/livestream_default.png';

export const MAX_USERNAME_LENGTH = 30;
export const MAX_NAME_LENGTH = 35;
export const MAX_SOCIAL_NAME_LENGTH = 35;
export const MAX_BIO_LENGTH = 150;
export const MAX_LOCATION_LENGTH = 30;

// filters for feed reipes
export const RECIPE_FILTERS = [
    {
        key: 0,
        name: 'none'
    },
    {
        key: 1,
        name: 'new',
        route: 'new'
    },
    {
        key: 2,
        name: 'local',
        route: 'local'
    },
    {
        key: 3,
        name: 'one pot',
        route: 'one-pot'
    },
    {
        key: 4,
        name: 'baking',
        route: 'baking'
    },
    {
        key: 5,
        name: 'western',
        route: 'western'
    },
    {
        key: 6,
        name: 'chinese',
        route: 'chinese'
    },
    {
        key: 7,
        name: 'low carb',
        route: 'low-carb'
    },
    {
        key: 8,
        name: 'breakfast',
        route: 'breakfast'
    },
    {
        key: 9,
        name: 'dessert',
        route: 'dessert'
    },
    {
        key: 10,
        name: 'pasta/noodles',
        route: 'pasta-noodles'
    },
    {
        key: 11,
        name: 'dinner',
        route: 'dinner'
    },
    {
        key: 12,
        name: 'vegetarian',
        route: 'vegetarian'
    },
    {
        key: 13,
        name: 'japanese',
        route: 'japanese'
    },
    {
        key: 14,
        name: 'soup',
        route: 'soup'
    },
    {
        key: 15,
        name: 'baby & me',
        route: 'babyandme'
    }
];

export const CHALLENGE_FILTERS = [
    {
        key: 0,
        name: 'none'
    },
    {
        key: 1,
        name: 'Active',
        route: 'active'
    },
    {
        key: 2,
        name: 'SG HOME CHEF IDOL',
        route: 'sg_home_chef_idol'
    },
    {
        key: 3,
        name: 'Ingredient',
        route: 'ingredient'
    },
    {
        key: 4,
        name: 'Creative',
        route: 'creative'
    },
    {
        key: 5,
        name: 'Upcoming',
        route: 'upcoming'
    },
    {
        key: 6,
        name: 'Daily',
        route: 'daily'
    },
    {
        key: 7,
        name: 'Baking',
        route: 'baking'
    },
    {
        key: 7,
        name: 'Completed',
        route: 'completed'
    }
];

export const dateTypeChip = {
    active: 0,
    starting: 1,
    ending: 2,
    completed: 3
};

export const SEARCH_FILTER = {
    ALL: 1,
    RECIPES: 2,
    CHALLENGES: 3,
    CREATORS: 4,
    GROUPS: 5
};

export const levels = {
    1: 'Easy',
    2: 'Medium',
    3: 'Hard'
};

export const cookingStoryType = {
    cookingStory: null,
    recommendation: 1,
    question: 2
};

export const TAGS_REGEX = {
    USER: /(@[A-Za-z0-9àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ._\s]{1,}#[0-9]{1,})/gm,
    RECIPE: /(\+[A-Za-z0-9àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ&._\s]{1,}#[0-9]{1,})/gm,
    CHALLENGE2: /\B(#[0-9a-zA-Z]+\b)(?!;)/,
    STRIP_USER: /@([A-Za-z0-9àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ._\s]{1,})#([0-9]{1,})/,
    STRIP_RECIPE: /\+([A-Za-z0-9àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ&._\s]{1,})#([0-9]{1,})/,
    STRIP_CHALLENGE: /\B(#[0-9a-zA-Z]+\b)(?!;)/
};

export const imageEndpoints = {
    rootS3Bucket: `${S3_MEDIA_BUCKET_URL}/`,
    recipeUrl: `${S3_MEDIA_BUCKET_URL}recipe/`,
    tipsUrl: `${S3_MEDIA_BUCKET_URL}tips/`,
    publishersUrl: `${S3_MEDIA_BUCKET_URL}publishers/`,
    ingredientsUrl: `${S3_MEDIA_BUCKET_URL}ingredients/`,
    announcementUrl: `${S3_MEDIA_BUCKET_URL}announcements/`,
    packsUrl: `${S3_MEDIA_BUCKET_URL}packs/`,
    sellersUrl: `${S3_MEDIA_BUCKET_URL}sellers/`,
    webinarsUrl: `${S3_MEDIA_BUCKET_URL}webinars/`,
    chefsUrl: `${S3_MEDIA_BUCKET_URL}chefs/`,
    logoUrl: `${S3_MEDIA_BUCKET_URL}assets/logo/`,
    dietsUrl: `${S3_MEDIA_BUCKET_URL}diets/`,
    organizerUrl: `${S3_MEDIA_BUCKET_URL}organizer/`,
    equipmentsUrl: `${S3_MEDIA_BUCKET_URL}equipments/`,
    learnMoreUrl: `${S3_MEDIA_BUCKET_URL}learn-more/`,
    commonIngredientsUrl: `${S3_MEDIA_BUCKET_URL}common-ingredients/`,
    defaultCookingNoteShare: `${S3_MEDIA_BUCKET_URL}cooking-note-share/image.png`,
    bannersUrl: `${S3_MEDIA_BUCKET_URL}banners/`,
    challengeUrl: `${S3_MEDIA_BUCKET_URL}challenges/`,
    challengeStatusIconUrl: `${S3_MEDIA_BUCKET_URL}challenges/status_icons/`,
    challengeIconUrl: `${S3_MEDIA_BUCKET_URL}challenges/icons/`,
    webAssets: `${S3_MEDIA_BUCKET_URL}web-assets/`,
    eventsBannerImageUrl: `${S3_MEDIA_BUCKET_URL}events/`
};

export const webAssets = {
    ogLandscapeLogo: `${imageEndpoints.webAssets}og-landscape-logo.png`,
    asianFood: `${imageEndpoints.webAssets}asian_food.png`,
    babyAndMe: `${imageEndpoints.webAssets}baby_and_me.png`,
    baking: `${imageEndpoints.webAssets}baking.png`,
    breakfast: `${imageEndpoints.webAssets}breakfast.png`,
    chineseFood: `${imageEndpoints.webAssets}chinese_food.png`,
    dessert: `${imageEndpoints.webAssets}dessert.png`,
    dinner: `${imageEndpoints.webAssets}dinner.png`,
    japanese: `${imageEndpoints.webAssets}japanese.png`,
    localFood: `${imageEndpoints.webAssets}local_food.png`,
    lowCarb: `${imageEndpoints.webAssets}low_carb.png`,
    onePot: `${imageEndpoints.webAssets}one_pot.png`,
    pasta: `${imageEndpoints.webAssets}pasta.png`,
    soup: `${imageEndpoints.webAssets}soup.png`,
    vegan: `${imageEndpoints.webAssets}vegan.png`,
    westernFood: `${imageEndpoints.webAssets}All-chlg.png`,
    allFilter: `${imageEndpoints.webAssets}SGHCI-chlg.png`,
    sghcFilter: `${imageEndpoints.webAssets}Ingredient-chlg.png`,
    ingredientFilter: `${imageEndpoints.webAssets}Creative-chlg.png`,
    creativeFilter: `${imageEndpoints.webAssets}western_food.png`,
    upcomingFilter: `${imageEndpoints.webAssets}Upcoming-chlg.png`,
    dailyFilter: `${imageEndpoints.webAssets}Daily-chlg.png`,
    bakingFilter: `${imageEndpoints.webAssets}Baking-chlg.png`,
    livestreamsBanner: `${imageEndpoints.webAssets}livestreams_banner.webp`,
    eventsBanner: `${imageEndpoints.webAssets}events_banner.webp`,
    topCollectionsBanner: `${imageEndpoints.webAssets}top_collections_banner.webp`
};

export const storeUrl = {
    appstore: 'https://apps.apple.com/us/app/yoripe-recipes-shop-share/id1344175332',
    playstore: 'https://play.google.com/store/apps/details?id=com.yoripeapp'
};

export const SGIdolJudges = [
    {
        name: 'Jamie Yeo',
        bio: ' Media Personality/Founder of Lula J Jewelry',
        image: JamieYeo,
        ig: 'iamjamieyeo',
        category: 'Category : Kids & Family',
        about: "Former actress/radio presenter who's now a full-time Mama and entrepreneur/content creator, Jamie loves whipping up healthy and tasty dishes with her family. The person who cooks most in her household though, is her husband!"
    },
    {
        name: 'Bryan, Trainer',
        image: Bryan,
        ig: 'bryanng_abccookingsg',
        category: 'Category : Western',
        about: 'Instructor Bryan is an instructor at ABC Cooking Studio Singapore (Funan Studio) and a trainer for cooking course. Other than conducting lessons, he has also done hosting and appeared in cooking tutorial videos to feature products from brand partners.'
    },
    {
        name: 'Chef Kai',
        image: ChefKai,
        ig: 'chezkaisg',
        category: 'Category : Bread & Perfect Beef & Lamb Moments',
        about: 'Having worked in acclaimed establishments of varying cuisines both locally and in Paris, Chef Kai started up his home dining establishment Chez Kai serving up modern Franco-Chinois cuisine.'
    },
    {
        name: 'Leslie Koh aka #RiceCookerMan',
        image: LeslieKoh,
        ig: 'leslie.koh',
        category: 'Category : Airfryer & Ricecooker Recipe',
        about: 'Also known as the #RiceCookerMan, you may have come across Leslie\'s insanely viral recipes on Facebook where he whips up popular dishes like muah chee using only a rice cooker. He is a huge foodie at heart and lives by his slogan of "Life\'s too short for lousy food."'
    },
    {
        name: 'Eric Neo / Executive Chef',
        image: EricNeo,
        ig: 'ericneobn',
        category: 'Category : Food Trends',
        about: 'To All Home Cooks! Come join in the fun!'
    },
    {
        name: 'Diana Gale, The Domestic Goddess Wannabe',
        image: DianaGale,
        ig: 'domestic_goddess_wannabe',
        category: 'Category : Dessert',
        about: 'Having started cooking and baking at the age of 10, Diana Gale is a 2-time winner of the Best Cooking Blog in the Singapore Blog Awards and a full-time culinary instructor. Her hands-on cooking and baking workshops empower you with the ability to recreate mouth-watering bakes and dishes for your family and friends, completely fuss-free! She is also the author of "The Domestic Goddess Wannabe Bakes" which represented Singapore for Best in the World in the Gourmand World Awards 2019 for the categories A13 Blogger and E03 Pastry. Check her workshops out at www.dgwworkshops.com!'
    },
    {
        name: 'Chef Pang Kok Keong',
        image: PangKok,
        ig: 'chef_pang',
        category: 'Category : Seafood',
        about: "Chef owner at Pang's Hakka Noodles and Chef at The Foreign Project Group. Chef Pang oversees the continuing menu and product development at all it's outlets."
    },
    {
        name: 'Leon Lim',
        image: LeonLin,
        ig: 'leonlimcl',
        category: 'Category : Spicy Food',
        about: 'Leon Lim has been an avid home cook for the past 16 years. Most notably, he was crowned as the 1st Runner-up of MasterChef Singapore Season and has gone on to open his own Halal Cafe, Creatr., in collaboration with Local Halal Ice Cream Cafe Chain, Three’s A Crowd Cafe.'
    }
];

export const SGIdolPartners = [
    {
        name: 'San Remo',
        image: SanRemo,
        url: 'https://www.instagram.com/sanremosg/'
    },
    {
        name: 'Indomie',
        image: Indomie,
        url: 'https://www.facebook.com/IndomieSG'
    },
    {
        name: 'Seaco',
        image: Seaco,
        url: 'https://seaco-online.com/'
    },
    {
        name: 'True Aussie Beef And Lamb',
        image: TrueAussie,
        url: 'https://www.trueaussiebeefandlamb.sg/'
    },
    {
        name: 'SongHe',
        image: SongHe,
        url: 'https://www.instagram.com/songhe.rice'
    },
    {
        name: 'RiceField',
        image: RiceField,
        url: 'https://www.tspsonghe.com.sg'
    },
    {
        name: 'EuropAce',
        image: EuropAce,
        url: 'https://www.facebook.com/EuropAceSingapore'
    }
];

export const SGIdolCategories = [
    {
        name: ' Airfryer & Rice cooker',
        image: AirFryer,
        recipes: [
            { name: 'Pandan Coconut Cake Using Airfryer', url: 'https://yoripeapp.app.link/Ho0dT8drFkb' },
            { name: 'Carb-free airfryer salmon “pie"', url: 'https://yoripeapp.app.link/t5CXvnW4tkb' },
            { name: 'Airfryer Easy Kueh Bingka (Tapioca Cake)', url: 'https://yoripeapp.app.link/ktMJrIVrFkb' },
            { name: 'Apple Pies using Airfryer', url: 'https://yoripeapp.app.link/2wcWayXrFkb' },
            { name: 'Japanese-styled Honey-Sake Pork Ribs with Asparagus using Airfryer', url: 'https://yoripeapp.app.link/2wcWayXrFkb' },
            { name: 'Turmeric Chicken Rice', url: 'https://yoripeapp.app.link/Fr8n5FesFkb' },
            { name: 'Tauchu Potato Chicken Stew', url: 'https://yoripeapp.app.link/FVRHfAgsFkb' },
            { name: 'Rice Cooker Recipe - Tomato Rice', url: 'https://yoripeapp.app.link/ZFlaaxjsFkb' },
            { name: 'Glutinous Rice with Chinese Sausage', url: 'https://yoripeapp.app.link/uxE6ZSksFkb' },
            { name: 'Rice Cooker Claypot Rice', url: 'https://yoripeapp.app.link/7rfMVbmsFkb' }
        ]
    },
    {
        name: 'Bread',
        image: Bread,
        recipes: [
            { name: 'Banana Bread', url: 'https://yoripeapp.app.link/C3CF2yxtFkb' },
            { name: 'Matcha & Black Sesame Sourdough Sandwich Loaf', url: 'https://yoripeapp.app.link/XS3auAAtFkb' },
            { name: 'Artisan Sourdough Bread', url: 'https://yoripeapp.app.link/ugF3fZBtFkb' },
            { name: 'Hokkaido Milk Loaf', url: 'https://yoripeapp.app.link/Pg8f0HEtFkb' },
            { name: 'No Oven Garlic Bread Recipe', url: 'https://yoripeapp.app.link/infcIDGtFkb' }
        ]
    },
    {
        name: 'Food Trends',
        image: FoodTrends,
        recipes: [
            { name: 'Drunken Tiramisu with Mr. Black', url: 'https://yoripeapp.app.link/vGEGySwEflb' },
            { name: 'Fried Wanton with Basil Pesto', url: 'https://yoripeapp.app.link/Zpy1u8xEflb' },
            { name: 'Mummy so Yummy', url: 'https://yoripeapp.app.link/C8JlLFzEflb' },
            { name: 'Spooky Mi Goreng', url: 'https://yoripeapp.app.link/kZ7A4IEEflb' },
            { name: 'Surprise Pancakes', url: 'https://yoripeapp.app.link/K1ChMXFEflb' }
        ]
    },
    {
        name: 'Dessert',
        image: Dessert,
        recipes: [
            { name: 'Speculoos Brownie', url: 'https://yoripeapp.app.link/RezZXKMtFkb' },
            { name: 'Chocolate Butter Cookies', url: 'https://yoripeapp.app.link/G1wz0UOtFkb' },
            { name: 'Mini Cheesecake - New York-Style', url: 'https://yoripeapp.app.link/79ozRdStFkb' },
            { name: 'Muah Chee', url: 'https://yoripeapp.app.link/rPdrqsUtFkb' },
            { name: 'Air Fryer Milo Lava Cake', url: 'https://yoripeapp.app.link/XrLITAVtFkb' }
        ]
    },
    {
        name: 'Seafood',
        image: SeaFood,
        recipes: [
            { name: 'Healthified Bbq Stingray', url: 'https://yoripeapp.app.link/mEsDPgssFkb' },
            { name: 'Baked Cheese Butterfly Prawns', url: 'https://yoripeapp.app.link/C0nvJeusFkb' },
            { name: 'Steamed Fish Fillet with Preserve Chopped Chili and Garlic', url: 'https://yoripeapp.app.link/wxwWSaEsFkb' },
            { name: 'Steamed Squid With Chilli And Lime Sauce', url: 'https://yoripeapp.app.link/0WyCVyFsFkb' },
            { name: 'Oven-baked Salmon with Sundried Tomato Pesto and Ice Plant Omelett', url: 'https://yoripeapp.app.link/DP1NkFIsFkb' }
        ]
    },
    {
        name: 'Western',
        image: Western,
        recipes: [
            { name: 'Avocado Chocolate Almond Cookies', url: 'https://yoripeapp.app.link/8I97H0PEflb' },
            { name: 'Creamy Garlic Chicken', url: 'https://yoripeapp.app.link/oTlItiREflb' },
            { name: 'Creamy Tuscan Salmon', url: 'https://yoripeapp.app.link/4atApPTEflb' },
            { name: 'Chicken Pesto Pasta with Coconut Milk', url: 'https://yoripeapp.app.link/cXQKJeWEflb' },
            { name: 'Crispy Fish Sliders', url: 'https://yoripeapp.app.link/TsARzEZEflb' }
        ]
    },
    {
        name: 'Beef & Lamb Perfect Moments',
        image: Beef,
        recipes: [
            { name: 'Beef Steak Quesadillas', url: 'https://yoripeapp.app.link/QfZApj3sFkb' },
            { name: 'Grilled Steak with Mushroom Sauce', url: 'https://yoripeapp.app.link/ot9fMIatFkb' },
            { name: 'Coffee And Togarashi Crusted Ribeye Steak', url: 'https://yoripeapp.app.link/K3h7ZTbtFkb' },
            { name: 'Korean Beef Lettuce Cups with Kimchi', url: 'https://yoripeapp.app.link/lpVUH7dtFkb' },
            { name: 'Pan-Fried Lamb Rack with Red Wine Garlic Sauce', url: 'https://yoripeapp.app.link/CwBKJ9gtFkb' }
        ]
    },
    {
        name: 'Kids & Family',
        image: KidsAndFamily,
        recipes: [
            { name: 'Pizza Bread', url: 'https://yoripeapp.app.link/0Nhe158Eflb' },
            { name: 'Blueberry Cheese Tart', url: 'https://yoripeapp.app.link/cjGkdigBelb' },
            { name: 'Minion Cheesy Frank Bites', url: 'https://yoripeapp.app.link/bxl6UvhFflb' },
            { name: 'Cheese Dorayaki', url: 'https://yoripeapp.app.link/YquT0frFflb' },
            { name: 'Spicy Brown Rice Porridge', url: 'https://yoripeapp.app.link/bcc0P9tFflb' }
        ]
    },
    {
        name: 'Spicy Food',
        image: Spicy,
        recipes: [
            { name: 'Spicy Numbing Stir-fry Pot', url: 'https://yoripeapp.app.link/CiaQewMsFkb' },
            { name: 'Korean Spicy Crispy Fried Chicken', url: 'https://yoripeapp.app.link/Q4zVqNOsFkb' },
            { name: 'Spicy Alfredo Linguine with Beef Bacon', url: 'https://yoripeapp.app.link/ypJaPiRsFkb' },
            { name: 'Kimchi Pork Belly', url: 'https://yoripeapp.app.link/IlkdPIVsFkb' },
            { name: 'Tom Yam Soup with Bee Hoon (Rice Vermicelli)', url: 'https://yoripeapp.app.link/fGkKkXZsFkb' }
        ]
    }
];

export const SGSemiJuniorJudges = [
    {
        name: 'Kate Insigne',
        bio: 'YoRipe Creator',
        image: KateInsigne,
        ig: 'kate_insigne',
        about: 'I’m not a master chef just a home chef for my kids and family. I love to cook and bake. #selfthought learned from trial and error. I hope to inspire people to cook even just a simple meal'
    },
    {
        name: 'Tri',
        bio: 'YoRipe Creator',
        image: Tri,
        ig: 'bakencookwith3',
        about: 'Tri is a mother of 2 teenage boys. Despite her busy schedule handling her own business, she determined to self-taught herself to bake and cook to serve nutritious, healthy, delicious food for her family.'
    }
];

export const SGFinalJuniorJudges = [
    {
        name: 'Jamie Yeo',
        bio: ' Media Personality/Founder of Lula J Jewelry',
        image: JamieYeo,
        ig: 'iamjamieyeo',
        about: "Former actress/radio presenter who's now a full-time Mama and entrepreneur/content creator, Jamie loves whipping up healthy and tasty dishes with her family. The person who cooks most in her household though, is her husband!"
    },
    {
        name: 'Leon Lim',
        bio: 'MasterChef Singapore Season 2 1st Runner-up',
        image: LeonLin,
        ig: 'leonlimcl',
        category: 'Category : Spicy Food',
        about: 'Leon Lim has been an avid home cook for the past 16 years. Most notably, he was crowned as the 1st Runner-up of MasterChef Singapore Season and has gone on to open his own Halal Cafe, Creatr., in collaboration with Local Halal Ice Cream Cafe Chain, Three’s A Crowd Cafe.'
    }
];

export const SGJuniorPartners = [
    {
        name: 'Seaco',
        image: Seaco,
        url: 'https://seaco-online.com/'
    },
    {
        name: 'Sustenir',
        image: Sustenir,
        url: 'https://www.instagram.com/susteniragriculture/'
    },
    {
        name: 'SongHe',
        image: SongHe,
        url: 'https://www.instagram.com/songhe.rice'
    },
    {
        name: 'RiceField',
        image: RiceField,
        url: 'https://www.tspsonghe.com.sg'
    },
    {
        name: 'True Aussie Beef And Lamb',
        image: TrueAussie,
        url: 'https://www.trueaussiebeefandlamb.sg/'
    },
    {
        name: 'EuropAce',
        image: EuropAce,
        url: 'https://www.facebook.com/EuropAceSingapore'
    }
];

export const SGJuniorPrizePartner = [
    {
        name: 'Crispy',
        image: Crispy,
        url: ''
    },
    {
        name: 'ALLSWELL',
        image: AllsWell,
        url: 'https://www.facebook.com/AllswellSG/'
    }
];

export const JuniorIdolRules = [
    'Open for kids 5 – 13 years old.',
    'Each participant can only enter the Competition once',
    'Each household can have more than 1 child entering the competition',
    'To participate, all entrants must upload a picture of a dish that they have cooked at home and post it with a description with the hashtag #YoripeJuniorChef2021 via YoRipe App.',
    'Nominations submissions end on 26 November 2021, Friday, 7pm',
    'During the cooking competition on 4, 5 & 12 Dec 2021, parents can help with cutting and chopping of ingredients and handling of hot equipment and utensils.',
    'Winning criteria for each round: voting (40%) and judges (60%) which makes up of presentation (50%), complexity of the dish (30%) and creativity (20%)',
    'All Winners will be tagged on YoRipe Winner announcement post on Facebook and Instagram. Each Winner will also be notified individually via WhatsApp, Facebook and/or Instagram private message.',
    'Shortlisted Winners will be required to acknowledge receipt of the Private Message by providing the information requested in the Private Message (including but not limited to full name and address of the Winners, date of birth, mobile number, email address and delivery address) to the Promoter by no later than 14 days after the Private Message is sent, failing which his/her Prize will be forfeited automatically and the Promoter shall be free to deal with the Prize in any manner as it sees fit.',
    'The organiser reserves the right in its sole and absolute discretion to change the Prizes as it sees fit and deal with any unclaimed Prizes in any manner.',
    'The Winners shall, if required by the Organiser, provide his/her original Identity Card / Passport for verification of proof of eligibility for receipt of the Prize.',
    'The Prizes will be dispatched to the relevant Winners within 30 days from the receipt of acknowledgement from the Winner',
    'The Organiser’s decisions as to any matter relating to the Competition, including but not limited to any entry eligibility, the winning entry(ies) and the Winner(s), will be final and binding. No correspondence will be entered into about the Competition or the Organiser’s decisions.'
];

export const IndoJuniorJudges = [
    {
        name: 'Syaiful Fahmi',
        bio: 'Chef Ambasador di @dr.steak_grill dan Vice President Bikers Chef MC Jakarta Chapter',
        image: Syaiful,
        ig: 'syfahmii',
        about: 'Dia pun aktif menjalani kegiatan offair bersama salah satu chef ternama yaitu Chef Aiko sebagai assistant nya dan Chef Syaiful Fahmi pun tengah menjalani bisnis Warung Kopi yang diberi nama Serupoet.'
    },
    {
        name: 'Zaidan Naufal Marsa',
        bio: 'Runner Up Junior Master Chef Indonesia Season 1',
        image: Zaidan,
        ig: 'zaidanmarsaa',
        about: 'Muda, Aktif dan Berprestasi. Zaidan pernah menjadi Traditional Market Ambassador bersama Danamon di tahun 2015 dan saat ini ia memiliki bisnis F&B bernama "Ronxly" yang menyediakan healthy food catering dan boba drink'
    },
    {
        name: 'Xinyan Fang',
        bio: 'CEO YoRipe',
        image: Xinyan,
        ig: 'chocolapinette',
        about: 'Xinyan adalah Pendiri dan CEO YoRipe, sebuah aplikasi memasak & belanjaan pertama di Asia Tenggara. Dia memulai YoRipe untuk membantu para profesional yang sibuk seperti dirinya untuk menjaga kesehatan mereka dengan lebih baik yaitu memasak hidangan yang mudah dan menyehatkan untuk mengurangi limbah makanan di rumah.'
    },
    {}
];

export const IndoJuniorPartners = [
    {
        name: 'MEG Cheese',
        image: Meg,
        url: 'https://instagram.com/megcheese.id?utm_medium=copy_link'
    }
];

export const IndoJuniorRules = [
    'Terbuka untuk anak-anak berusia 5 – 13 tahun.',
    'Setiap peserta hanya dapat mengikuti Kompetisi satu kali (1 foto hidangan saja) pada Tahap I.',
    'Setiap keluarga dapat memiliki lebih dari 1 anak yang mengikuti kompetisi.',
    'Untuk berpartisipasi, semua peserta harus mengunggah gambar hidangan yang telah mereka masak di rumah dan mempostingnya dengan deskripsi dan tagar #YoRipeJuniorChefIDSeason1 melalui Aplikasi YoRipe. Aplikasi YoRipe sudah tersedia di Appstore dan Playstore.',
    'Tahap I (Nominasi & Voting) berakhir pada 2 Januari 2022, Minggu, 23.59 WIB.',
    'Selama kompetisi memasak pada tanggal 8 Januari 2022, orang tua dapat membantu memotong bahan dan menangani peralatan-peralatan yang panas dan tajam.',
    'Kriteria pemenang : voting (40%) dan juri (60%) yang terdiri dari presentasi (50%), kompleksitas hidangan (30%) dan kreativitas (20%).',
    'Semua Pemenang akan ditandai di postingan pengumuman Pemenang YoRipe di Facebook dan Instagram. Setiap Pemenang juga akan diberitahukan secara individual melalui pesan pribadi WhatsApp, Facebook dan/atau Instagram.',
    'Pemenang Terpilih akan diminta untuk membalas Pesan Pribadi dengan memberikan informasi yang diminta dalam Pesan Pribadi tersebut (termasuk namun tidak terbatas pada nama dan alamat lengkap Pemenang, tanggal lahir, nomor ponsel, alamat email dan alamat pengiriman) ke Penyelenggara selambat-lambatnya 14 hari setelah Pesan Pribadi dikirim. Jika gagal, Hadiahnya akan hangus secara otomatis dan Penyelenggara bebas menangani Hadiah dengan cara apa pun yang dianggap sesuai.',
    'Penyelenggara berhak atas kebijakannya sendiri dan mutlak untuk mengubah Hadiah yang dianggap cocok dan menangani Hadiah yang tidak diklaim dengan cara apa pun.',
    'Pemenang harus (jika diminta oleh Penyelenggara) memberikan Kartu Identitas / Paspor aslinya untuk verifikasi bukti kelayakan untuk menerima Hadiah.',
    'Hadiah akan dikirim ke Pemenang terkait dalam waktu 30 hari sejak diterimanya pengakuan dari Pemenang.',
    'Keputusan Penyelenggara mengenai masalah apa pun yang berkaitan dengan Kompetisi, termasuk namun tidak terbatas pada kelayakan entri, entri yang menang dan Pemenang, akan bersifat final dan mengikat. Tidak ada korespondensi yang akan dilakukan tentang Kompetisi atau keputusan Penyelenggara.'
];

export const TimelineData = [
    {
        key: 1,
        text: '1. Starting Ramadan Meal Plan, Teasing Ramadan Home Chef Idol',
        image: Timeline1

    },
    {
        key: 2,
        text: '2. Starting Ramadan Homechef Idol 2022',
        image: Timeline2
    },
    {
        key: 3,
        text: '3. Ramadan Cooking Livestream',
        image: Timeline3
    },
    {
        key: 4,
        text: '4. Ramadan Homechef Idol Winner Announcement',
        image: Timeline4
    },
    {
        key: 5,
        text: '5. Closing Ramadan Meal Plan with Hari Raya Menu and Recap',
        image: Timeline5
    }
];

export const KompetisiData = [
    {
        key: 1,
        text: '#MenuSehatSahur',
        image: Kompetisi1,
        link: 'https://yoripeapp.app.link/3uDZhjsiSob'

    },
    {
        key: 2,
        text: '#IftarSegarBersamaYeos',
        image: Kompetisi2,
        link: 'https://yoripeapp.app.link/LKkuxeziSob'
    },
    {
        key: 3,
        text: '#InspirasiTakjilHemat',
        image: Kompetisi3,
        link: 'https://yoripeapp.app.link/1dDxvCxiSob'
    },
    {
        key: 4,
        text: '#SajianKueRayaWithMEGCheese',
        image: Kompetisi4,
        link: 'https://yoripeapp.app.link/HVDH7hriSob'
    },
    {
        key: 5,
        text: '#KreasiRamadanBarengMaduEnak',
        image: Kompetisi5,
        link: 'https://yoripeapp.app.link/nrHmyWriSob'
    }
];

export const JudgeData = [
    {
        key: 1,
        image: Juri1,
        name: 'Dini Dinda',
        about: 'Master Chef Indonesia Season 7',
        ig: 'dinidinda',
        creator: false
    },
    {
        key: 2,
        image: Juri2,
        name: 'Leni Asmawati',
        about: 'F&B Business Owner',
        ig: 'leniasmawati_',
        creator: true
    },
    {
        key: 3,
        image: Juri3,
        name: 'Sofia Imany',
        about: 'TikTok Food Content Creators',
        tiktok: 'cookbysofia',
        creator: true
    },
    {
        key: 4,
        image: Juri4,
        name: 'Chef Erwinsyah',
        about: 'Head of Culinary Department Joyful Cooking',
        ig: 'joyfulcookingid',
        creator: false
    },
    {
        key: 5,
        image: Juri5,
        name: 'Ely Ismawanti',
        about: 'Founder pawonhebat.id',
        ig: 'elyyyis_',
        creator: true
    }
];

export const BrandPartners = [
    {
        key: 1,
        image: MaduEnak
    },
    {
        key: 2,
        image: MegBrand
    },
    {
        key: 3,
        image: YeosBrand
    }
];

export const CommunityPartners = [
    {
        key: 1,
        image: 'https://yoripe-s3-production.s3.ap-southeast-1.amazonaws.com/web-assets/Avela.png'
    },
    {
        key: 2,
        image: BVLogo
    },
    {
        key: 3,
        image: HipweeYoung
    },
    {
        key: 4,
        image: SocialConnext
    },
    {
        page: 5,
        image: MamaSupport
    },
    {
        key: 6,
        image: KawanMommel
    },
    {
        key: 7,
        image: MomToMom
    },
    {
        key: 8,
        image: 'https://yoripe-s3-production.s3.ap-southeast-1.amazonaws.com/web-assets/PawonHebat.png'
    }
];
