import loadable from '@loadable/component';
import ROUTE_PATHS from 'config/routePaths';
import SGJuniorBakeOff2024 from 'containers/SGJuniorBakeOff2024';
import React from 'react';
import { Redirect } from 'react-router';
import { Route, Switch } from 'react-router-dom';

const TopBar = loadable(() => import('components/TopBar'));
const JuniorIdol = loadable(() => import('containers/JuniorIdol'));
const TeenChefIdol = loadable(() => import('containers/TeenChefIdol'));
const RamadanLP = loadable(() => import('containers/RamadanLP'));
const RamadanLP2023 = loadable(() => import('containers/RamadanLP2023'));
const HealthierLp = loadable(() => import('containers/HealthierLP'));
const IndoJuniorChef = loadable(() => import('containers/IndoJuniorChef'));
const JuniorChef2 = loadable(() => import('containers/JuniorChef2'));
const CNY2022 = loadable(() => import('containers/CNY2022'));
const AliceNero = loadable(() => import('containers/AliceNero'));
const AyamBrandCookbook = loadable(() => import('containers/AyamBrandCookbook'));
const PrivacyPolicy = loadable(() => import('containers/PrivacyPolicy'));
const RamadanMealPlan = loadable(() => import('containers/RamadanMealPlan'));
const FestiveCookbook = loadable(() => import('containers/FestiveCookbook'));
const CNYFestiveCookbook = loadable(() => import('containers/CNYFestiveCookbook'));
const BelcubeCookbook = loadable(() => import('containers/BelcubeCookbook'));
const KrimKafeCookbook = loadable(() => import('containers/KrimKafeCookbook'));
const CNY2023 = loadable(() => import('containers/CNY2023'));
const KidBaker = loadable(() => import('containers/KidBaker'));
const SugarFree = loadable(() => import('containers/SugarFree'));
const DoleFruitPops = loadable(() => import('containers/DoleFruitPops'));
const DOLE2023 = loadable(() => import('containers/DOLE2023'));
const DoleMYS2023 = loadable(() => import('containers/DoleMYS2023'));
const AlceNeroWorkshop = loadable(() => import('containers/AlceNero2023'));
const FamilyDay2023 = loadable(() => import('containers/FamilyDay2023'));
const DelMonteCNYContest = loadable(() => import('containers/DelMonteCNYContest'));
const DoleBuyAndWin2024 = loadable(() => import('containers/DoleBuyAndWin2024'));
const BackToSchoolLaughingCow = loadable(() => import('containers/BackToSchoolLaughingCow'));
const DoleICanDoIt = loadable(() => import('containers/DoleICanDoIt'));
const ICanDoleItCookbook = loadable(() => import('containers/ICanDoleItCookbook'));
const DelMonteSchoolBootcamp = loadable(() => import('containers/DelMonteSchoolBootcamp'));
const IndoJuniorChef2024 = loadable(() => import('containers/IndoJuniorChef2024'));
const CNYFundraising = loadable(() => import('containers/CNYFundraising'));
const NotFound = loadable(() => import('containers/NotFound'));
const SgIdolLandingPage = loadable(() => import('containers/HomeChef2022'));
const SgHomeChefLp = loadable(() => import('containers/SgIdolLandingPage'));
const FeedStoriesContainer = loadable(() => import('containers/Stories'));
const FindMyAccountContainer = loadable(() => import('containers/FindMyAccount'));

function Routes() {
    return (
        <div className='position-relative root-inner-wrapper'>
            <TopBar />
            <Switch>
                <Route path={ROUTE_PATHS.DEFAULT} exact={true} component={FeedStoriesContainer} />
                <Route path={ROUTE_PATHS.FIND_MY_ACCOUNT} exact={true} component={FindMyAccountContainer} />
                <Route path={ROUTE_PATHS.PRIVACY_POLICY} exact={true} component={PrivacyPolicy} />
                <Route path={ROUTE_PATHS.SGLP_IDOL} exact={true} component={() => <Redirect to={ROUTE_PATHS.SGLP} />} />
                <Route path={ROUTE_PATHS.SGLP} exact={true} component={SgHomeChefLp} />
                <Route path={ROUTE_PATHS.SG_HOME_CHEF_2022} exact={true} component={SgIdolLandingPage} />
                <Route path={ROUTE_PATHS.SGJUNIOR} exact={true} component={JuniorIdol} />
                <Route path={ROUTE_PATHS.SGTEENS} exact={true} component={TeenChefIdol} />
                <Route path={ROUTE_PATHS.CNY_2022} exact={true} component={CNY2022} />
                <Route path={ROUTE_PATHS.CNY_2023} exact={true} component={CNY2023} />
                <Route path={ROUTE_PATHS.CNY_2023_JOINED} exact={true} component={CNY2023} />
                <Route path={ROUTE_PATHS.KID_BAKER} exact={true} component={KidBaker} />
                <Route path={ROUTE_PATHS.KID_BAKER_JOINED} exact={true} component={KidBaker} />
                <Route path={ROUTE_PATHS.SUGAR_FREE} exact={true} component={SugarFree} />
                <Route path={ROUTE_PATHS.ALCE_NERO_WORKSHOP} exact={true} component={AlceNeroWorkshop} />
                <Route path={ROUTE_PATHS.ALCE_NERO_WORKSHOP_JOINED} exact={true} component={AlceNeroWorkshop} />
                <Route path={ROUTE_PATHS.FAMILY_DAY_2023} exact={true} component={FamilyDay2023} />
                <Route path={ROUTE_PATHS.FAMILY_DAY_2023_JOINED} exact={true} component={FamilyDay2023} />
                <Route path={ROUTE_PATHS.DEL_MONTE_CNY_CONTEST} exact={true} component={DelMonteCNYContest} />
                <Route path={ROUTE_PATHS.DEL_MONTE_CNY_CONTEST_JOINED} exact={true} component={DelMonteCNYContest} />
                <Route path={ROUTE_PATHS.DEL_MONTE_SCHOOL_BOOTCAMP} exact={true} component={DelMonteSchoolBootcamp} />
                <Route path={ROUTE_PATHS.DEL_MONTE_SCHOOL_BOOTCAMP_JOINED} exact={true} component={DelMonteSchoolBootcamp} />
                <Route path={ROUTE_PATHS.BACK_TO_SCHOOL} exact={true} component={BackToSchoolLaughingCow} />
                <Route path={ROUTE_PATHS.BACK_TO_SCHOOL_JOINED} exact={true} component={BackToSchoolLaughingCow} />
                <Route path={ROUTE_PATHS.SUGAR_FREE_JOINED} exact={true} component={SugarFree} />
                <Route path={ROUTE_PATHS.DOLE_2023} exact={true} component={DOLE2023} />
                <Route path={ROUTE_PATHS.DOLE_2023_JOINED} exact={true} component={DOLE2023} />
                <Route path={ROUTE_PATHS.DOLE_MYS} exact={true} component={DoleMYS2023} />
                <Route path={ROUTE_PATHS.DOLE_MYS_JOINED} exact={true} component={DoleMYS2023} />
                <Route path={ROUTE_PATHS.DOLE_BUY_AND_WIN_2024} exact={true} component={DoleBuyAndWin2024} />
                <Route path={ROUTE_PATHS.DOLE_BUY_AND_WIN_2024_JOINED} exact={true} component={DoleBuyAndWin2024} />
                <Route path={ROUTE_PATHS.SG_JUNIOR_BAKE_OFF} exact={true} component={SGJuniorBakeOff2024} />
                <Route path={ROUTE_PATHS.SG_JUNIOR_BAKE_OFF_JOINED} exact={true} component={SGJuniorBakeOff2024} />
                <Route path={ROUTE_PATHS.JUNIOR_CHEF_2024} exact={true} component={IndoJuniorChef2024} />
                <Route path={ROUTE_PATHS.JUNIOR_CHEF_2024_JOINED} exact={true} component={IndoJuniorChef2024} />
                <Route path={ROUTE_PATHS.DOLE_I_CAN_DO_IT} exact={true} component={DoleICanDoIt} />
                <Route path={ROUTE_PATHS.CNY_FUNDRAISING} exact={true} component={CNYFundraising} />
                <Route path={ROUTE_PATHS.INDO_RAMADA} exact={true} component={RamadanLP} />
                <Route path={ROUTE_PATHS.INDO_RAMADA_2023} exact={true} component={RamadanLP2023} />
                <Route path={ROUTE_PATHS.ALICE_NERO} exact={true} component={AliceNero} />
                <Route path={ROUTE_PATHS.AYAM_COOKBOOK} exact={true} component={AyamBrandCookbook} />
                <Route path={ROUTE_PATHS.FESTIVE_COOKBOOK} exact={true} component={FestiveCookbook} />
                <Route path={ROUTE_PATHS.RAMADAN_MEAL_PLAN} exact={true} component={RamadanMealPlan} />
                <Route path={ROUTE_PATHS.CNY_FESTIVE_COOKBOOK} exact={true} component={CNYFestiveCookbook} />
                <Route path={ROUTE_PATHS.I_CAN_DO_IT_COOKBOOK} exact={true} component={ICanDoleItCookbook} />
                <Route path={ROUTE_PATHS.DOLE_FRUIT_POPS} exact={true} component={DoleFruitPops} />
                <Route path={ROUTE_PATHS.BELCUBE} exact={true} component={BelcubeCookbook} />
                <Route path={ROUTE_PATHS.KRIMKAFE} exact={true} component={KrimKafeCookbook} />
                <Route path={ROUTE_PATHS.HEALTHEIRLP} exact={true} component={HealthierLp} />
                <Route path={ROUTE_PATHS.INDO_JUNIOR} exact={true} component={IndoJuniorChef} />
                <Route path={ROUTE_PATHS.INDO_JUNIOR_2} exact={true} component={JuniorChef2} />
                <Route component={NotFound} />
                <Route path='/not-found' component={NotFound} />
            </Switch>
        </div>
    );
}

export default Routes;
